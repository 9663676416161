<!-- =========================================================================================
  File Name: AddNewDataSidebar.vue
  Description: Add New Data - Sidebar component
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->


<template>
  <vs-sidebar
    click-not-close
    position-right
    parent="body"
    default-index="1"
    color="primary"
    class="add-new-data-sidebar items-no-padding"
    spacer
    v-model="isSidebarActiveLocal"
  >
    <div class="mt-6 flex items-center justify-between px-6">
      <h4>العلامات التجارية</h4>
      <feather-icon
        icon="XIcon"
        @click.stop="isSidebarActiveLocal = false"
        class="cursor-pointer"
      ></feather-icon>
    </div>
    <vs-divider class="mb-0"></vs-divider>
    <component
      :is="scrollbarTag"
      class="scroll-area--data-list-add-new"
      :settings="settings"
      :key="$vs.rtl"
    >
      <div class="mt-6 px-6">
        <vs-input
          label="اسم العلامة"
          v-model="brand.name"
          class="mt-5 w-full"
          name="name"
        />
        <span class="text-danger text-sm" v-show="errors.has('name')">{{
          errors.first("name")
        }}</span>
        <vs-input
          label="قيمة الزيادة (نسبة مئوية)"
          v-model="brand.increase_percentage"
          class="mt-5 w-full"
          name="name"
          v-if="brand.id == ''"
        />
        <div class="mt-5">
          <label for="original_currency_id">عملة الشراء</label>
          <v-select
            :disabled="brand.id != ''"
            label="base"
            data-vv-as="عملة الشراء"
            id="original_currency_id"
            :options="currencies"
            v-model="brand.original_currency_id"
            :reduce="(item) => item.id"
            v-validate="'required'"
            :dir="$vs.rtl ? 'rtl' : 'ltr'"
            name="original_currency_id"
            class="w-full"
          ></v-select>
          <span
            class="text-danger text-sm"
            v-show="errors.has('original_currency_id')"
            >{{ errors.first("original_currency_id") }}</span
          >
        </div>
        <div class="mt-5">
          <label for="selling_currency_id">عملة البيع</label>
          <v-select
            :disabled="brand.id != ''"
            label="base"
            id="selling_currency_id"
            data-vv-as="عملة البيع"
            :options="currencies"
            v-model="brand.selling_currency_id"
            :reduce="(item) => item.id"
            v-validate="'required'"
            :dir="$vs.rtl ? 'rtl' : 'ltr'"
            name="selling_currency_id"
            class="w-full"
          ></v-select>
          <span
            class="text-danger text-sm"
            v-show="errors.has('selling_currency_id')"
            >{{ errors.first("selling_currency_id") }}</span
          >
        </div>
        <div
          v-if="brand.logo.length"
          class="brand-img mt-10 text-center"
          @click="spliceImage"
        >
          <img :src="brand.logo[0].blob || brand.logo[0]" id="preview-image" />
        </div>
        <file-upload
          v-if="!brand.logo.length"
          ref="upload"
          v-model="brand.logo"
          class="vs-button vs-col bg-primary mt-10"
          post-action="/post.method"
          put-action="/put.method"
          extensions="jpg,gif,png,webp"
          :maximum="1"
          @input-filter="inputFilter"
        >
          تنزيل صورة
        </file-upload>
      </div>
    </component>
    <div class="flex flex-wrap items-center p-6" slot="footer">
      <vs-button
        class="mr-6"
        @click="submitData"
        :disabled="!isFormValid && loading"
        >حفظ</vs-button
      >
      <vs-button
        type="border"
        color="danger"
        @click="isSidebarActiveLocal = false"
        >إلغاء</vs-button
      >
    </div>
  </vs-sidebar>
</template>

<script>
import VueUploadComponent from "vue-upload-component";
import vSelect from "vue-select";
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import { mapActions } from "vuex";
export default {
  props: {
    isSidebarActive: {
      type: Boolean,
      required: true,
    },
    data: {
      type: Object,
      default: () => {},
    },
  },
  components: {
    "file-upload": VueUploadComponent,
    VuePerfectScrollbar,
    vSelect,
  },
  data() {
    return {
      loading: false,
      brand: {
        name: "",
        logo: [],
        original_currency_id: "",
        selling_currency_id: "",
        increase_percentage: "",
      },
      settings: {
        // perfectScrollbar settings
        maxScrollbarLength: 30,
        wheelSpeed: 1,
        swipeEasing: true,
      },
    };
  },
  watch: {
    isSidebarActive(val) {
      if (!val) return;
      if (Object.entries(this.data).length === 0) {
        this.initValues();
        this.$validator.reset();
      } else {
        this.brand.id = this.data.id;
        this.brand.name = this.data.name;
        this.brand.increase_percentage = this.data.increase_percentage;
        this.brand.selling_currency_id = this.data.selling_currency_id;
        this.brand.original_currency_id = this.data.original_currency_id;
        this.brand.logo[0] = this.data.logo;
      }
    },
  },
  computed: {
    isSidebarActiveLocal: {
      get() {
        return this.isSidebarActive;
      },
      set(val) {
        if (!val) {
          this.$emit("closeSidebar");
        }
      },
    },
    isFormValid() {
      return !this.errors.any();
    },
    scrollbarTag() {
      return this.$store.getters.scrollbarTag;
    },
    currencies() {
      return this.$store.state.HomeStats.currencies;
    },
  },
  created() {
    this.fetchCurrencies();
  },
  methods: {
    ...mapActions("HomeStats", ["fetchCurrencies"]),
    spliceImage() {
      this.brand.logo.splice(0, 1);
    },
    inputFilter: function (newFile, oldFile, prevent) {
      if (newFile && !oldFile) {
        // Filter non-image file
        if (!/\.(jpeg|jpg|png)$/i.test(newFile.name)) {
          this.$vs.notify({
            title: "خطأ",
            text: "يجب ان تكون الصورة من الامتداد jpeg او png",
            color: "danger",
          });
          return prevent();
        }
        if (newFile.size / 1024.0 > 5000) {
          this.$vs.notify({
            title: "خطأ",
            text: "يجب ان تكون الصورة اصغر من 2 mb",
            color: "danger",
          });
          return prevent();
        }
      }
      // Create a blob field
      newFile.blob = "";
      let URL = window.URL || window.webkitURL;
      if (URL && URL.createObjectURL) {
        newFile.blob = URL.createObjectURL(newFile.file);
      }
    },
    initValues() {
      if (this.data.id) return;
      this.brand.id = "";
      this.brand.name = "";
      this.brand.selling_currency_id = "";
      this.brand.original_currency_id = "";
      this.brand.increase_percentage = "";
      this.brand.name = "";
      this.brand.logo = [];
    },
    submitData() {
      if (!this.loading) {
        this.$validator.validateAll().then((result) => {
          if (result) {
            let brand_id = this.brand.id;
            this.loading = true;
            var bodyFormData = new FormData();
            bodyFormData.set("name", this.brand.name);
            if (!brand_id) {
              bodyFormData.set(
                "selling_currency_id",
                this.brand.selling_currency_id
              );
              bodyFormData.set(
                "original_currency_id",
                this.brand.original_currency_id
              );
              let increase = this.brand.increase_percentage / 100 + 1;
              bodyFormData.set("increase_percentage", increase);
            }

            bodyFormData.set(
              "logo",
              this.brand.logo.length ? this.brand.logo[0].file : null
            );

            if (brand_id) {
              this.$store
                .dispatch("brand/updateBrand", { brand_id, bodyFormData })
                .then((response) => {
                  this.loading = false;
                  this.$emit("closeSidebar");
                  this.initValues();
                  this.successDialog(response.data.message);
                })
                .catch((error) => {
                  this.loading = false;
                  this.errorDialog(error);
                });
            } else {
              this.$store
                .dispatch("brand/createNewBrand", bodyFormData)
                .then((response) => {
                  this.loading = false;
                  this.$emit("closeSidebar");
                  this.initValues();
                  this.successDialog(response.data.message);
                })
                .catch((error) => {
                  this.loading = false;
                  this.errorDialog(error);
                });
            }
          }
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
#preview-image {
  max-width: 30%;
}
.add-new-data-sidebar {
  ::v-deep .vs-sidebar--background {
    z-index: 52010;
  }

  ::v-deep .vs-sidebar {
    z-index: 52010;
    width: 400px;
    max-width: 90vw;

    .img-upload {
      margin-top: 2rem;

      .con-img-upload {
        padding: 0;
      }

      .con-input-upload {
        width: 100%;
        margin: 0;
      }
    }
  }
}

.scroll-area--data-list-add-new {
  // height: calc(var(--vh, 1vh) * 100 - 4.3rem);
  height: calc(var(--vh, 1vh) * 100 - 16px - 45px - 82px);

  &:not(.ps) {
    overflow-y: auto;
  }
}
.brand-img {
  position: relative;
  color: #ff0000;
  font-weight: 700;
  &:hover {
    cursor: pointer;
    opacity: 0.6;

    &:after {
      content: "X";
      position: absolute;
      bottom: 40%;
      left: 50%;
    }
  }
}
</style>
